body {
  background-color: black;
  color: white;
}

.app {
  text-align: center;
}

.app img {
  width: 100%;
  height: 100%;
  max-width: 500px;
}

.link-container {
  width: 200px;
  margin: 50px auto 25px auto;
  display: flex;
  justify-content: space-between;
}

.link {
  flex: none;
  width: 30px;
}

.link:first-child {
  margin-right: 35px;
}

.link:last-child {
  margin-left: 35px;
}

svg {
  fill: #ffffff;
}

/* Bug fix for carousel */
.react-images__view-image {
  max-height: 100vh !important;
}

.menu-close {
  font-size: x-large;
}

.menu-item-container {
  width: 200px;
  display: flex;
  flex-direction: column;
}

.menu-item {
  padding: 20px 50px;
}

.menu-item a {
  text-decoration: none;
  color: white;
}

.menu-button {
  background-color: black;
  color: white;
  border: none;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif
}

.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }

.material-icons.md-light { 
  color: rgba(255, 255, 255, 1); 
}